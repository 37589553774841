<script>
import stripeMixin from '@app/mixins/stripe';
import BundlePricePlans from '@app/components/BundlePricePlans.vue';

export default {
  mixins: [stripeMixin],
  components: { BundlePricePlans },
  head: {
    title: 'Paramètres',
  },
  forms: [
    {
      price: {
        label: 'Prix (TTC)',
        type: 'number',
        column: 'is-6',
        inputAttrs: {
          min: 0,
          max: 10000,
          step: 0.01,
        },
      },
      discount_price: {
        label: 'Prix promotionnel (TTC)',
        type: 'number',
        column: 'is-6',
        inputAttrs: {
          min: 0,
          max: 10000,
          step: 0.01,
        },
      },
      discount_price_reason: {
        label: 'Raison de la promotion',
        type: 'text',
        column: 'is-12',
        inputAttrs: {
          maxlength: 64,
          hasCounter: false,
        },
        notification: `
          <p>
            Vous pouvez ajouter une raison à votre promotion. Cela renforce son efficacité et sa légitimité.
          </p>
          <p>
            Elle sera affichée en dessous du prix promotionnel, sur votre page de pack.
            <br>
            Exemple : "Promotion de noël" ou "Prix de lancement"
          </p>
        `,
        notificationClass: 'content',
      },
      rate_vat: {
        label: '% de TVA (inclus dans le prix)',
        type: 'number',
        column: 'is-12',
        inputAttrs: {
          min: 0,
          max: 100,
          step: 0.01,
        },
      },
    },
  ],
  created() {
    this.$options.forms[0].rate_vat.inputAttrs.placeholder = `
      Par défaut : ${this.authStore.default_vat}%
    `.trim();

    this.hydrate();
  },
  data() {
    return {
      isLoading: {
        0: false,
      },
      bundle: {
        price: '',
        discount_price: '',
        discount_price_reason: '',
        rate_vat: '',
      },
    };
  },
  computed: {
    currentBundle() {
      return this.$store.state.bundles.current;
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    hasDiscountPrice() {
      return !Number.isNaN(parseFloat(this.bundle.discount_price));
    },
  },

  methods: {
    hydrate() {
      Object.keys(this.bundle)
        .forEach((key) => (this.bundle[key] = this.currentBundle[key]));
    },
    handle(form, fk) {
      const bundle = {};

      Object.keys(form)
        .forEach((key) => (bundle[key] = this.bundle[key]));

      bundle.price !== undefined && (bundle.price = bundle.price || 0);
      bundle.discount_price !== undefined && (bundle.discount_price = bundle.discount_price || null);
      bundle.rate_vat !== undefined && (bundle.rate_vat = bundle.rate_vat || null);

      this.isLoading[fk] = true;
      this.$store.dispatch('bundles/update', { uuid: this.currentBundle.uuid, bundle, isCurrent: true })
        .then(() => this.$showMessage.success())
        .catch(this.handleStripeError)
        .finally(() => (this.isLoading[fk] = false));
    },
  },
};
</script>

<template>
  <div>
    <AppFeatureWrapper>
      <template #title>
        Tarif de votre pack
      </template>
      <template #content>
        <p>
          Détaillez tous les aspects tarifaires de votre pack comme le prix (en €) ou encore la TVA.
        </p>
        <p>
          Vous avez la possibilité de définir un prix promotionnel. <strong>Mais attention,
            dans ce cas, les codes promo ne s'appliqueront pas lors de l'achat du pack.</strong>
        </p>
      </template>

      <template #form>
        <form v-for="(form, fk) in $options.forms" :key="(form, fk)" ref="form" class="box"
          @submit.prevent="dataIsValid(isLoading[fk]) && handle(form, fk)">
          <div class="columns is-multiline">
            <div v-for="(field, key) in form"
              v-show="field.ifModelName == null || training[field.ifModelName] == field.ifModelValue" :key="key"
              class="column" :class="field.column">
              <BaseField v-show="key != 'discount_price_reason' || (key == 'discount_price_reason' && hasDiscountPrice)"
                v-model="bundle[key]" :field="field" />
            </div>
          </div>

          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading[fk]">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </template>
    </AppFeatureWrapper>
    <BundlePricePlans />
  </div>
</template>
