<script>
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  data: () => ({
    isLoading: false,
    frequencies: {
      MONTHLY: {
        name: 'Mensuel',
        description: 'tous les 30 jours',
        nb: 30,
      },
      EVERY_TWO_WEEKS: {
        name: 'Bimensuel',
        description: 'tous les 14 jours',
        nb: 14,
      },
      WEEKLY: {
        name: 'Hebdomadaire',
        description: 'tous les 7 jours',
        nb: 7,
      },
    },
    newPaypmentPlan: {
      amount: '',
      nb_schedule: '',
      frequency: 'MONTHLY',
    },
  }),
  computed: {
    currentBundle() {
      return this.$store.state.bundles.current;
    },
  },
  methods: {
    handle() {
      const paymentPlan = { ...this.newPaypmentPlan };
      this.isLoading = true;
      this.$store.dispatch('bundles/addPaymentPlan', {
        uuid: this.currentBundle.uuid,
        paymentPlan,
      })
        .then(() => Object.assign(this.$data, this.$options.data.apply(this)))
        .finally(() => (this.isLoading = false));
    },
    deleteFrequency(paymentPlan) {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'Attention ! Cette action est définitive',
        focusOn: 'cancel',
        onConfirm: () => {
          const loader = this.$buefy.loading.open({ container: this.$refs.table });
          this.$store.dispatch('bundles/deletePaymentPlan', {
            uuid: this.currentBundle.uuid,
            paymentPlan,
          })
            .finally(() => loader.close());
        },
      });
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #title>
      Ajoutez des facilités de paiements
      <AppFeatureBadge class="ml-3 is-size-5" position="is-right" />
    </template>
    <template #content>
      <p>
        Votre pack a un prix élevé ? <br>
        Proposez à vos clients de le <strong>payer en plusieurs fois</strong>.
      </p>
      <p>
        Vous avez la possibilité de proposer plusieurs facilités de paiement.
        Pour chacune d'elle, définissez le prix d'un paiement, le nombre d'échéances
        ainsi que l'intervalle entre chaque paiement.
        <strong>Attention, si un tarif n'a pas été défini (ci-dessus),
          le pack restera par défaut gratuit.</strong>
      </p>
      <p>
        <strong>Le premier paiement est déclenché au moment de l'achat</strong>
        et conditionne l'acquisition des contenus du pack. Mais si les prochains paiements échouent,
        vous en serez notifié et pourrez bloquer les accès de l'apprenant.
      </p>
      <p>
        Sachez que les facilités de paiement <strong>ne sont pas disponibles avec PayPal</strong>.
        Elles ne seront proposées à votre client que lorsqu'il choisira un paiement par carte bancaire.
      </p>
    </template>
    <template #form>
      <GoProWrapper>
        <div ref="table" class="box is-relative">
          <b-table :data="currentBundle.payment_schedules">
            <b-table-column v-slot="{ row }" field="amount" label="Informations" sortable>
              <strong>
                {{ row.amount | formatPrice }} x {{ row.nb_schedule }} paiements
                {{ frequencies[row.frequency].description }}
              </strong>
              <br>
              = {{ (row.amount * row.nb_schedule) | formatPrice }} payé au total
            </b-table-column>
            <b-table-column cell-class="py-5 is-size-8" v-slot="{ row }" field="created_at" label="Créée" sortable
              numeric>
              <b-tooltip :label="$moment.utc(row.created_at).local().format('DD/MM/YY [à] HH:mm')">
                {{ row.created_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </b-table-column>
            <b-table-column v-slot="{ row }">
              <b-button class="has-text-danger" type="is-text" size="is-small" icon-left="trash" outlined
                @click="deleteFrequency(row)" />
            </b-table-column>
            <template #empty>
              Vous n'avez pas encore créé de facilités de paiement.
            </template>
          </b-table>
        </div>

        <form class="box" @submit.prevent="handle">
          <b-field label="Intervalle" label-for="frequency">
            <b-select v-model="newPaypmentPlan.frequency" id="frequency" expanded required>
              <option v-for="(frequency, k) in frequencies" :key="k" :value="k">
                {{ frequency.name }}
              </option>
            </b-select>
          </b-field>
          <b-field grouped>
            <b-field label="Montant d'un paiement" label-for="amount" expanded>
              <b-input v-model="newPaypmentPlan.amount" id="amount" type="number" step="0.01" required />
            </b-field>
            <b-field label="Nombre d'échéances" label-for="nb_schedule" expanded>
              <b-input v-model="newPaypmentPlan.nb_schedule" id="nb_schedule" type="number" min="2" required />
            </b-field>
          </b-field>
          <b-field>
            <div class="is-flex is-justify-content-space-between">
              <p class="control w-full is-flex is-align-items-center">
                <template v-if="newPaypmentPlan.amount != '' && newPaypmentPlan.nb_schedule != ''">
                  <strong>Prix total</strong> :
                  {{ (newPaypmentPlan.amount * newPaypmentPlan.nb_schedule) | formatPrice }}
                </template>
              </p>
              <b-button type="is-primary" native-type="submit" :loading="isLoading">
                Ajouter ce prix
              </b-button>
            </div>
          </b-field>
        </form>
      </GoProWrapper>
    </template>
  </AppFeatureWrapper>
</template>
